import { useEffect, useState } from 'react'
import {
  BannerContent,
  BlockBanner,
  BlockButton,
  BlockContent,
  BlockImage,
  BlockInfo,
  BlockListAvatar,
  ContentSwap,
  ContentTrade,
  ImageBanner,
  ImageTrade,
  ItemAvatar,
  TextChange,
  TextInfo,
  TextTime,
  TextTitle,
  TitleBanner,
  TitleDesc,
  TitleInfo,
  WrapTextChange,
} from '../../style'

const Banner = () => {
  const options = ['Swap', 'Liquidity', 'Mining']
  const [currentOptionIndex, setCurrentOptionIndex] = useState(0)
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentOptionIndex((prevIndex) => (prevIndex + 1) % options.length)
    }, 1000)
    return () => clearInterval(interval)
  }, [options.length])

  const getVisible = (index) => {
    return currentOptionIndex === index ? 'visible' : 'hidden'
  }
  return (
    <BlockBanner>
      <ImageBanner src="/images/banner-dashboard1.webp" alt="" width="0" height="0" sizes="100vw" />
      <BannerContent>
        <TitleBanner />
      </BannerContent>
      <ContentTrade>
        <BlockImage>
          <ImageTrade src="/images/banner-dashboard2.webp" alt="" width="0" height="0" sizes="100vw" />
        </BlockImage>
        <BlockContent>
          <TextTitle>Getting profit today</TextTitle>
          <BlockListAvatar>
            <ItemAvatar src="/images/avt1.jpeg" alt="" width="0" height="0" sizes="100vw" />
            <ItemAvatar src="/images/avt2.jpg" alt="" width="0" height="0" sizes="100vw" />
            <ItemAvatar src="/images/avt3.jpg" alt="" width="0" height="0" sizes="100vw" />
          </BlockListAvatar>
          <TextTime>3 minutes ago</TextTime>
        </BlockContent>
      </ContentTrade>
      <ContentSwap>
        <BlockImage>
          <ImageTrade src="/images/banner-dashboard3.png" alt="" width="0" height="0" sizes="100vw" />
        </BlockImage>
        <BlockContent>
          <TextTitle>Swap today</TextTitle>
          <BlockListAvatar>
            <ItemAvatar src="/images/avt2.jpg" alt="" width="0" height="0" sizes="100vw" />
            <ItemAvatar src="/images/avt3.jpg" alt="" width="0" height="0" sizes="100vw" />
            <ItemAvatar src="/images/avt1.jpeg" alt="" width="0" height="0" sizes="100vw" />
          </BlockListAvatar>
          <TextTime>5 minutes ago</TextTime>
        </BlockContent>
      </ContentSwap>
      <BlockInfo>
        <TitleInfo>
          <WrapTextChange>
            {options.map((option, index) => (
              <TextChange
                style={{
                  visibility: getVisible(index),
                }}
              >
                {option}
              </TextChange>
            ))}
          </WrapTextChange>
          <TextInfo>with BFF Swap</TextInfo>
        </TitleInfo>
        <TitleDesc>Trade anything. No registration, no hassle.</TitleDesc>
        {/* <BlockButton>Get Started</BlockButton> */}
      </BlockInfo>
    </BlockBanner>
  )
}

export default Banner
