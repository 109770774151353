import Banner from './components/Banner'
import Features from './components/Features'
import Tokenomics from './components/Tokenomics'
import { BlockDashboard, BlockDashboardWrap } from './style'

const Dashboard = () => {
  return (
    <BlockDashboard>
      <BlockDashboardWrap className="blockContainer">
        <Banner />
        <Features />
        <Tokenomics />
      </BlockDashboardWrap>
    </BlockDashboard>
  )
}

export default Dashboard
