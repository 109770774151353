import React from 'react'

import ChartContainer from './ChartContainer'
import {
  BlockTokenomics,
  BlockTokenomicsWrap,
  ContentWrap,
  InfoTokenomics,
  ItemTokenomics,
  LinkDirect,
  WrapChartTokenomics,
  WrapCircle,
  WrapLinkDirect,
} from './style'

const Tokenomics = () => {
  const address = '0x5c0b850f8905b9c562cccc6b6ce0889bda7e7fb1'

  return (
    <BlockTokenomics id="tokenomics">
      <BlockTokenomicsWrap className="blockContainer">
        <ContentWrap>
          <WrapChartTokenomics>
            <WrapCircle>
              <ChartContainer />
            </WrapCircle>
            <InfoTokenomics>
              <ItemTokenomics className="bold">Name Token : BIG FINANCE FUND</ItemTokenomics>
              <ItemTokenomics className="bold">Symbol Token : BFF</ItemTokenomics>
              <ItemTokenomics className="bold">Chain : BEP20</ItemTokenomics>
              <ItemTokenomics className="bold">Total Supply: 100.000.000</ItemTokenomics>
              <ItemTokenomics className="bold">Initial Price : 1 BFF = 1$</ItemTokenomics>
              <br />
              <ItemTokenomics>
                Founder : 30% <span>- 30.000.000 BFF</span>
              </ItemTokenomics>
              <ItemTokenomics>
                Adviser : 5% <span>- 5.000.000 BFF</span>
              </ItemTokenomics>
              <ItemTokenomics>
                Ecosystem : 15% <span>- 15.000.000 BFF</span>
              </ItemTokenomics>
              <ItemTokenomics>
                Marketing : 10% <span>- 10.000.000 BFF</span>
              </ItemTokenomics>
              <ItemTokenomics>
                Community : 30% <span>- 30,000,000 BFF</span>
              </ItemTokenomics>
              <ItemTokenomics>
                Media & Team : 10% <span>- 10,000,000 BFF</span>
              </ItemTokenomics>
              <br />
              <ItemTokenomics>
                Token Address
                <WrapLinkDirect>
                  <LinkDirect href={`https://bscscan.com/address/${address}`} target="_blank">
                    BscScan
                  </LinkDirect>
                </WrapLinkDirect>
              </ItemTokenomics>
            </InfoTokenomics>
          </WrapChartTokenomics>
        </ContentWrap>
      </BlockTokenomicsWrap>
    </BlockTokenomics>
  )
}
export default Tokenomics
