import Link from "next/link";
import styled from "styled-components";

export const BlockTokenomics = styled.div`
  position: relative;
  padding: 0px 0px 100px 0px;
`;
export const BlockTokenomicsWrap = styled.div``;

export const WrapChartTokenomics = styled.div`
  display: flex;
  margin: 75px 0px 0px 0px;
  @media screen and (max-width: 1199px) {
    flex-direction: column;
    align-items: center;
    margin: 10px 0px 0px 0px;
  }
`;

export const ImgBackground = styled.img`
  width: 100%;
  height: 100%;
  @media screen and (max-width: 1199px) {
    position: absolute;
    object-fit: cover;
  }
`;

export const ContentWrap = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0px auto 0px auto;
  @media screen and (max-width: 1199px) {
    position: relative;
    padding: 0px 0px 200px 0px;
  }
`;

export const InfoTokenomics = styled.div`
  width: 100%;
  background: var(--colors-backgroundElement);;
  backdrop-filter: blur(10px);
  border-radius: 15px;
  margin: 0px 0px 0px 20px;
  padding: 30px;
  @media screen and (max-width: 1199px) {
    margin: 20px 0px 0px 0px;
  }
`;

export const ItemTokenomics = styled.div`
  font-family: SUISSE_BOLD;
  margin: 10px 0px 0px 0px;
  font-size: 1.6rem;
  line-height: calc(1.6rem * 1.25);
  word-break: break-word;
  color: var(--colors-color);
  @media screen and (max-width: 1199px) {
    font-size: 1.25rem;
    line-height: calc(1.25rem * 1.25);
  }
  span {
    font-family: SUISSE_BOLD;
    color : #34b3c3;
  }
  &.bold {
    color : #34b3c3;
    font-family: SUISSE_BLACK;
  }
`;

export const WrapCircle = styled.div`
    display : flex;
    width: 100%;
    max-width: 450px;
    background : ${({ theme }) => theme.colors.backgroundElement};
    align-items : center;
    border-radius : 20px;
    padding: 20px;
    justify-content : center;
    margin : 0px 0px 0px 0px;
    position : relative;
    svg {
      position : absolute;
      filter : blur(20px)
      z-index : 0;
    }
`;

export const WrapChart = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
  align-items: center;
  justify-content: center;
`;

export const BlockLogo = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  pointer-events : none;
`;

export const ImgLogo = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
`;

export const WrapLinkDirect = styled.div`
  display: flex;
  margin: 15px 0px 0px 0px;
`;

export const LinkDirect = styled(Link)`
  height: 40px;
  width: fit-content;
  margin: 0px 5px 0px 0px;
  padding: 0px 10px;
  cursor: pointer;
  background : ${({ theme }) => theme.colors.gradientPrimary};
  border-radius: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  font-family: SUISSE_BOLD;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  transition: 0.4s all;
  &:hover {
    filter: brightness(0.8);
    transition: 0.4s all;
  }
`;

export const WrapTitle = styled.div`
  margin: 0px 0px 50px 0px;
`;

export const TextTitle = styled.div`
  margin: 0px 0px 30px 0px;
  font-size: 2.8rem;
  line-height: calc(2.8rem * 1.25);
  font-family: F_BOLD;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  @media screen and (max-width: 1199px) {
    font-size: 2rem;
    line-height: calc(2rem * 1.25);
  }
`;
