import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m15.389,11.1c0,.972-1.448,2.784-2.801,3.868-.053.043-.123.042-.176,0-1.353-1.084-2.801-2.896-2.801-3.868,0-.606.424-1.1.944-1.1.436,0,.944.238.944.909,0,.552.447,1,1,1s1-.448,1-1c0-.671.509-.909.944-.909.521,0,.944.494.944,1.1ZM23.999,2.801c-.215,6.847-1.473,11.833-3.737,14.817-1.843,2.428-4.618,3.974-7.615,4.241-.308.028-.614.041-.918.041-2.246,0-4.358-.744-6.072-2.142l-3.949,3.949c-.195.195-.451.293-.707.293s-.512-.098-.707-.293c-.391-.391-.391-1.023,0-1.414l3.949-3.948c-1.406-1.714-2.193-3.829-2.24-6.085-.057-2.735.978-5.307,2.912-7.241,2.417-2.417,5.254-2.604,7.997-2.783,2.422-.159,4.926-.323,7.508-1.925.658-.411,1.475-.414,2.184-.004.866.5,1.427,1.502,1.396,2.495Zm-6.61,8.299c0-1.709-1.321-3.1-2.944-3.1-.759,0-1.433.255-1.944.688-.512-.433-1.186-.688-1.944-.688-1.623,0-2.944,1.391-2.944,3.1,0,2.555,3.405,5.312,3.551,5.429.394.315.866.473,1.338.473s.944-.158,1.338-.473c.146-.116,3.551-2.874,3.551-5.429Z"
      />
    </Svg>
  );
};

export default Icon;
