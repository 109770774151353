import { Features1, Features2, Features3, Features4 } from '@pancakeswap/uikit'
import { BlockFeatures, ContentFeatures, FeaturesItem, ImageFeatures, TitleFeatures } from 'views/Dashboard/style'

const Features = () => {
  return (
    <BlockFeatures>
      <FeaturesItem>
        <Features1 />
        <TitleFeatures>5.000 people join every day</TitleFeatures>
        <ContentFeatures>Join the market to mine BFF Swap and get huge profits.</ContentFeatures>
        <ImageFeatures src="/images/ft1.png" alt="" width={0} height={0} sizes="100vw" />
      </FeaturesItem>
      <FeaturesItem>
        <Features2 />
        <TitleFeatures>Game Play NFT</TitleFeatures>
        <ContentFeatures>BFF Swap game play ecosystem NFT, Token BFF</ContentFeatures>
        <ImageFeatures src="/images/ft2.png" alt="" width={0} height={0} sizes="100vw" />
      </FeaturesItem>
      <FeaturesItem>
        <Features3 />
        <TitleFeatures>Mining, Swap, Liquidity</TitleFeatures>
        <ContentFeatures>
          All you need for a web3 platform, every transaction is transparent, fast and secure
        </ContentFeatures>
        <ImageFeatures src="/images/ft3.png" alt="" width={0} height={0} sizes="100vw" />
      </FeaturesItem>
      <FeaturesItem>
        <Features4 />
        <TitleFeatures>DEX tokens by market cap</TitleFeatures>
        <ContentFeatures>Every command and every transaction is recorded on the blockchain.</ContentFeatures>
        <ImageFeatures src="/images/ft4.png" alt="" width={0} height={0} sizes="100vw" />
      </FeaturesItem>
    </BlockFeatures>
  )
}

export default Features
